<script setup>
import worldHeatMap from "@/assets/images/educationAndTraning/worldHeatMap.png";
import QuantumMastersData from "@/jsonData/QuantumMastersData.json";
import { computed, ref, watch } from "vue";
import DropdownSingle from "@/components/forms/DropdownSingle.vue";

const countries = QuantumMastersData.map((data) => data.Country);
const uniqueCountries = [...new Set(countries)];
const currentCountry = ref("");
const page = ref(1);
const perPage = 10;

// Filtered data based on the selected country
const QuantumMastersDataToShow = computed(() => {
  if (currentCountry.value === "" || currentCountry.value === "All") {
    return QuantumMastersData;
  }
  return QuantumMastersData.filter((data) =>
    data.Country.includes(currentCountry.value)
  );
});

const pages = computed(() =>
  Math.ceil(QuantumMastersDataToShow.value.length / perPage)
);

// Paginated data for the current page
const paginatedData = computed(() => {
  const start = (page.value - 1) * perPage;
  const end = start + perPage;
  return QuantumMastersDataToShow.value.slice(start, end);
});

const filterCountry = (country) => {
  currentCountry.value = country;
  console.log(country);
};

// Watch for changes in currentCountry and reset the page to 1
watch(currentCountry, () => {
  page.value = 1;
});
</script>
<template>
  <main>
    <h1 class="heading1">
      Quantum Masters
      <hr class="gradientLine" />
    </h1>
    <div
      class="grid k1:grid-cols-2 grid-cols-1 bg-slate-100 p-8 gap-6 text-justify"
    >
      <div>
        <!--        <h2 class="gradientText heading2 !mb-0 pb-4 w-fit">Heat map</h2>-->
        <p>
          Workforce development is a key component of many nations' quantum
          strategies, and Master programs have emerged as a potential solution
          to this challenge. The EQRC is actively analyzing the landscape of
          tertiary quantum education to recommend the best approaches for
          developing quantum specialists. In this paper, we have mapped the
          global landscape of quantum Master's programs, analyzing their
          establishment year, evolution, faculties responsible for running the
          programs, internship opportunities, and expected career outcomes.
        </p>
      </div>

      <img
        :src="worldHeatMap"
        alt="World Heat Map"
        class="object-contain rounded-2xl"
      />
    </div>

    <div>
      <!--      <div class="flex gap-1.5 flex-wrap py-3">-->
      <!--        <div-->
      <!--          v-for="country in uniqueCountries"-->
      <!--          :key="country"-->
      <!--          class="button w-fit !text-sm !px-2 !py-1"-->
      <!--          :class="-->
      <!--            currentCountry.value === country ? 'main border' : 'secondary'-->
      <!--          "-->
      <!--          @click="-->
      <!--            currentCountry.value =-->
      <!--              currentCountry.value === country ? '' : country-->
      <!--          "-->
      <!--        >-->
      <!--          {{ country }}-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        class="flex justify-center items-center flex-col gap-2 relative mt-8"
      >
        <div class="k1:absolute k1:top-1/2 k1:-translate-y-1/2 right-0 z-10">
          <DropdownSingle
            title="Select Countries"
            :items="['All', ...uniqueCountries]"
            :showSearch="true"
            :buttonClass="'button !text-sm !px-4 !py-1 !rounded-sm secondary hover:main'"
            placeholder="Country"
            @select="filterCountry"
          ></DropdownSingle>
        </div>
        <div class="flex justify-center gap-2 py-4 relative">
          <div
            v-for="number in pages"
            :key="number"
            class="button !text-sm !px-2 !py-1 w-8 !rounded-sm"
            :class="number === page ? 'main' : 'secondary'"
            @click="page = number"
          >
            {{ number }}
          </div>
        </div>
      </div>
      <div class="text-center text-gray-500 text-sm">
        Total masters
        <span v-if="currentCountry !== 'All' && currentCountry !== ''">
          in {{ currentCountry }}:
        </span>
        {{ QuantumMastersDataToShow.length }}
      </div>

      <table class="table-fixed w-full rounded-lg overflow-hidden">
        <thead class="bg-slate-100 text-gray-500 text-sm rounded-2xl">
          <tr>
            <th class="k1:px-4 px-1 pt-4 p-2 border-b border-gray-200">
              Country
            </th>
            <th class="k1:px-4 px-1 pt-4 p-2 border-b border-gray-200">City</th>
            <th class="k1:px-4 px-1 pt-4 p-2 border-b border-gray-200">
              University
            </th>
            <th class="k1:px-4 px-1 pt-4 p-2 border-b border-gray-200">
              Program
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="data in paginatedData"
            :key="data.University + data.Program"
            class="hover:bg-gray-100 k1:text-sm text-xs leading-tight"
          >
            <td
              class="k1:px-4 px-1 py-1 k1:py-2 border-b border-x border-gray-200 k1:whitespace-nowrap"
            >
              {{ data.Country }}
            </td>
            <td
              class="k1:px-4 px-1 py-1 k1:py-2 border-b border-x border-gray-200 k1:whitespace-nowrap"
            >
              {{ data.City }}
            </td>
            <td
              class="k1:px-4 px-1 py-1 k1:py-2 border-b border-x border-gray-200"
            >
              {{ data.University }}
            </td>
            <td
              class="k1:px-4 px-1 py-1 k1:py-2 border-b border-x border-gray-200"
            >
              <a
                :href="data.Link"
                class="cursor-pointer gradientText hoverUnderline"
              >
                {{ data.Program }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>
